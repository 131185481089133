import '../styles/globals.css'
import {appWithTranslation} from 'next-i18next';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'

function MyApp({Component, pageProps}) {
    return <LocalizationProvider dateAdapter={AdapterDateFns}><Component {...pageProps} /></LocalizationProvider>
}

export default appWithTranslation(MyApp);
